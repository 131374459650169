import React from 'react';

const CookiesEn = () => {
  return (
    <div className="EN">
      <div>
        <p className="p1">
          <span className="s1"><b>WHAT IS A COOKIE AND WHAT ARE THEY USED FOR?</b></span>
        </p>
        <p className="p1">
          <span className="s1">A cookie is a small data file which, in the form of a unique anonymous code, is sent to your browser from a web server and then stored on the hard disk of your device (computer, smart-phone and/or tablet). Cookies may allow the correct use of an internet site (so-called technical or necessary cookies), or they may act as checks of the User's preferences in the context of the latter's online navigation in order to propose advertising messages (so-called profiling cookies). Cookies may be stored permanently on the device for a period of variable duration (so-called permanent cookies), or they may be deleted on the closure of the browser or be of limited duration (so-called session cookies). Cookies may be installed by the website you are visiting (so-called first party cookies) or may be installed by other websites (so-called third-party cookies).</span>
        </p>
        <br/>
        <p className="p1">
          <span className="s1"><b>THE COOKIES USED ON THIS WEBSITE AND THE PURPOSES OF SUCH USE</b></span>
        </p>
        <p className="p1">
          <span className="s1">This Website uses the following cookies:</span>
        </p>
        <br/>
        <p className="p1">
          <span className="s1"><b>Necessary/Technical cookies</b></span>
        </p>
        <p className="p1">
          <span className="s1">These cookies allow the Website to function correctly making it possible to view its contents in the related language and market from the User’s first visit, then this is the purpose of said necessary technical cookies. They are able to recognize the country from which User is making the connection and ensure that the User is automatically orientated to the version of the Website applicable for his or her country on each visit. They also make it possible to create an account, to log in and manage orders. If you are a registered User, the Website will be able to recognize you when accessing the services offered to registered users as a result of the cookies. These cookies are able to tell if Users purchase products on the Website through an affiliated or partner Site (Tradedoubler and Rakuten), making it possible for us to fulfil our obligations in relation to such partner Sites (then the purpose is to fulfil an obligation of VALENTINO linked to the purchase). These cookies are necessary for the functioning of the Website.</span>
        </p>
        <br/>
        <p className="p1">
          <span className="s1"><b>List of cookies</b></span>
        </p>
        <p className="p1">
          <span className="s1">A cookie is a small amount of data (a text file) that a website, asks the browser to store on the device when visited by a user in order to remember their information, such as their preferred language or login details. These cookies are set by us and called first-party cookies. We also use third-party cookies (cookies from a domain other than the website you are visiting) for advertising and marketing purposes. In particular, we use cookies and other tracking technologies for these purposes:</span>
        </p>
        <br/>
        <div className="my-10" >
          <table className="w-full table-auto">
            <thead>
              <tr>
                <td>Cookie Subgroup</td>
                <td>Cookies</td>
                <td>Purpose</td>
                <td>Cookies used</td>
                <td>Lifespan</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>valentino.com</td>
                <td>Browser Language</td>
                <td>Default Language</td>
                <td>First Party</td>
                <td>Session</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br/>
        <p className="p1">
          <span className="s1"><b>Analytic Cookies</b></span>
        </p>
        <p className="p1">
          <span className="s1">These cookies are used to create statistical analyses on the manner in which Users navigate the Website, for example the device used, the number of pages visited or the number of clicks on a particular page during navigation. The results of these analyses are processed in an anonymous way and only for statistics purposes. Then statistical aggregated analysis about the use of the Website is the purposes of the processing. We only use cookies which track anonymous IP addresses (with a portion of the code not visible); such cookies are assimilated to technical cookies as they do not allow any users identification and they do not need any consent to be used.</span>
        </p>
        <br/>
        <p className="p1">
          <span className="s1"><b>DATA RETENTION</b></span>
        </p>
        <p className="p1">
          <span className="s1">Please note that the lifespan of each cookie is indicated within the tables above. The data collected by the cookies will be retained by the Data Controller for a maximum of 37 months and then cancelled or anonymized.</span>
        </p>
        <br/>
        <p className="p1">
          <span className="s1"><b>LEGAL BASIS FOR THE USE OF COOKIES</b></span>
        </p>
        <p className="p1">
          <span className="s1">Consent is not required for the use of technical cookies (including anonymized analytical cookies); they are thus processed on the basis of the owner's legitimate interest to provide improved navigation and use of the Website.</span>
        </p>
        <br/>
        <p className="p1">
          <span className="s1"><b>HOW YOUR PERSONAL DATA ARE PROCESSED</b></span>
        </p>
        <p className="p1">
          <span className="s1">Your personal data will be processed by automated tools for the time strictly necessary to achieve the purposes for which such data were collected (please see the specific cookie duration on the tables above and the DATA RETENTION section above). Specific security, technical and organizational measures have been adopted to prevent the loss of data, illicit or incorrect use and unauthorized access.</span>
        </p>
        <br/>
        <p className="p1">
          <span className="s1"><b>USERS RIGHT TO OPPOSE OR EDIT COOKIES</b></span>
        </p>
        <p className="p1">
          <span className="s1">You can oppose the storage of cookies on your hard disk by configuring your browser in such a way as to deactivate cookies. We have set out below the procedures available in the main browsers:</span>
        </p>
        <p className="p1">
          <span className="s1">• Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=it">https://support.google.com/chrome/answer/95647?hl=it</a></span>
        </p>
        <p className="p1">
          <span className="s1">• Firefox: <a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie">https://support.mozilla.org/it/kb/Gestione%20dei%20cookie</a></span>
        </p>
        <p className="p1">
          <span className="s1">• Safari: <a href="http://support.apple.com/kb/HT1677?viewlocale=it_IT">http://support.apple.com/kb/HT1677?viewlocale=it_IT</a></span>
        </p>

        <p className="p1">
          <span className="s1">It is possible, however, that after completing the above operation a number of the web page functions may not work properly.</span>
        </p>
        <br/>
        <p className="p1">
          <span className="s1"><b>DATA CONTROLLER</b></span>
        </p>
        <p className="p1">
          <span className="s1">The data controller of data collected by cookies is Valentino S.p.A. with registered office in Via Turati 16/18, 20121, Milano (MI), Italia. Valentino S.p.A. has appointed a data protection officer, who can be contacted at the following e-mail address <a href="mailto:privacy@valentino.com">privacy@valentino.com</a>. For any information regarding the processing of your personal data, to complain and to exercise the rights set out below, you can also contact the data controller writing to Valentino, via Turati 16/18, 20121 Milano (at the attention of the Legal Department, which is appointed to receive and manage such requests, also involving the relevant departments, as per the internal Valentino procedures).</span>
        </p>
        <br/>
        <p className="p1">
          <span className="s1"><b>WHO WILL OBTAIN KNOWLEDGE OF THE DATA, THEIR TRANSFER AND DISCLOSURE</b></span>
        </p>
        <p className="p1">
          <span className="s1">The data acquired by cookies will and may come to the knowledge of the following:</span>
        </p>
        <p className="p1">
          <span className="s1">• Employees and collaborators of the controller, acting in the capacity of person authorized to effect processing; and,</span>
        </p>
        <p className="p1">
          <span className="s1">• Suppliers of technical and organizational services acting in the capacity of processors.</span>
        </p>
        <p className="p1">
          <span className="s1">We may transfer the data acquired by the use of cookies abroad including to countries outside the EU, with the guarantee nonetheless, of appropriate protections and defenses. To obtain information on the exact location of the data you are invited to write to the controller at <a href="mailto:privacy@valentino.com">privacy@valentino.com</a>.</span>
        </p>
        <p className="p1">
          <span className="s1">The data will not be disclosed generally in any way.</span>
        </p>
        <br/>
        <p className="p1">
          <span className="s1"><b>USERS RIGHTS</b></span>
        </p>
        <p className="p1">
          <span className="s1">The user always has the right to obtain from VALENTINO confirmation of whether or not personal data concerning him/her exists, even if it is not yet recorded, and to have it communicated to him/her in an intelligible form. A user also has the right to obtain information about the source of personal data; the purposes and methods of processing it, the logic applied in the event of processing that is performed with the aid of electronic instruments; the identification details of the controller and data processors; and indication of the persons or categories of persons whose personal data may be communicated. A user also has the right to request an update, correction or, when s/he has an interest in doing so, an inclusion of personal data, deletion, conversion to an anonymous form or the blocking of personal data, that has been processed in violation of the law, including data which it is not necessary to keep in relation to the purposes for which it was collected or subsequently processed; a statement that the above operations were disclosed, including in terms of their content, to those parties to whom the data was communicated, except in the case in which such performance proves impossible or entails the use of methods that are clearly disproportionate to the right protected.
             The user can also ask for the portability of his/her data. Moreover, the user has the right to ask restriction of processing of his/her personal data.</span>
        </p>
        <br/>
        <p className="p1">
          <span className="s1">A user nevertheless has the right to object, in part or in full, for legitimate reasons, the processing of personal data concerning him/her, even if it is pertinent to the scope of the
          collection.</span>
        </p>
        <br/>

        <p className="p1">
          <span className="s1">You can exercise the above rights and ask information and further questions about the processing of your Personal Data by contacting VALENTINO and the Data Protection Officer writing to <a href="mailto:privacy@valentino.com">privacy@valentino.com</a> (please note that the delivery of an email request entails the subsequent acquisition of the sender's address, necessary to respond to requests and keep track of them, as well as any other personal data included in the electronic communication, for legal purposes). You can also write by mail at VALENTINO, via Turati 16/18, 20121, Milano (Italy), at the attention of the Legal Department.</span>
        </p>
        <br/>
        <p className="p1">
          <span className="s1">Please finally note that you have the right to lodge a complaint with the Italian Data Protection Authority (Garante per la Protezione dei Dati Personali), based in Piazza Venezia, 11, 00187 Rome (<a href="http://www.garanteprivacy.it/">www.garanteprivacy.it</a>), or with another data protection supervisory authority in the Country in which you reside.</span>
        </p>
      </div>
    </div>
  );
};

export default CookiesEn;
