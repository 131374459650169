import React from 'react';

const CookiesCn = () => {
  return (
    <div className="EN">
      <div>
     
        <p className="p1">
          <span className="s1">
            什么是cookie？其用途是什么？
            <br/>
            cookie是一种小型数据文件，以独特的匿名代码形式从网络服务器发送到您的浏览器，然后存储在您的设备(电脑、智能手机和/或平板电脑)的硬盘上。cookie可能允许正确地使用某个
            互联网站点(这被称为技术cookie或必要的cookie)，或者它们可能在用户在线导航的背景下检查用户的偏好，以便提供广告信息(这被称为分析性cookie)。
            <br/>
            cookie可能会在一段可变的持续时间内永久存储在设备上(这被称为永久cookie)，或者它们可能会在浏览器关闭时被删除，或者是在有限的持续时间存在(这被称为会话cookie)。cookie
            可能由您正在访问的网站安装(这被称为第一方cookie)，也可能由其他网站安装(这被称为第三方cookie)。
            <br/>
            <br/>
            本网站使用的cookie及其使用目的
            <br/>
            本网站使用以下cookie：
            <br/>
            <br/>
            必要的/技术cookies
            <br/>
            这些cookie允许本网站正常运行，从而可以在用户第一次访问时查看相关语言和市场的内容，这就是所述的必要的技术cookie的目的。他们能够识别用户连接的国家，并确保用户每次
            访问时自动定向到适用于其国家的网站版本。这些cookie是网站运行所必需的。
            <br/>
            <br/>
            cookie列表
            <br/>
            cookie是网站要求浏览器在用户访问设备时存储在设备上的少量数据(文本文件)，以便记住用户的信息，例如他们的首选语言或登录详细信息。这些cookie是由我们设置的，被称为第
            一方cookie。我们还使用第三方cookie(来自您正在访问的网站以外的域名的cookie)，将其用于广告和营销目的。具体而言，我们将cookie和其他跟踪技术用于以下目的:
          </span>
        </p>
        <br/>
        
        <div className="my-10" >
          <table className="w-full table-auto">
            <thead>
              <tr>
                <td>Cookie子群</td>
                <td>Cookies</td>
                <td>目的</td>
                <td>使用的cookie</td>
                <td>有效期</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>valentino.com</td>
                <td>浏览器语言</td>
                <td>默认语言</td>
                <td>第一方</td>
                <td>会话</td>
              </tr>
            </tbody>
          </table>
        </div>

        <br/>
        <p className="p1">
          <span className="s1">
          分析性cookie
          <br/>
          这些cookie用于对用户浏览网站的方式进行统计分析，例如使用的设备、访问的页面数量或在浏览过程中对特定页面的点击次数。这些分析的结果以匿名方式处理，仅用于统计目的。
          然后对有关本网站使用情况的统计汇总分析进行处理。我们只使用跟踪匿名IP地址的cookie(部分代码不可见);此类cookie被同化为技术cookie，因为它们不允许进行任何的用户识别，
          也不需要任何同意即可被使用。
          <br/>
          <br/>
          数据保留
          <br/>
          请注意，每个cookie的寿命都在上面的表格中。由cookie收集的数据将由数据控制方保留最多[请参阅下面的列表]，然后注销或匿名化。
          <br/>
          Adobe 分析（Adobe Analytics）：37个月
          <br/>
          <br/>
          使用cookie的法律依据
          <br/>
          使用技术cookie(包括匿名分析的cookie)无需征得同意；因此，对它们的处理基于所有者的合法利益，以提供更好的网站导航和使用。
          <br/>
          <br/>
          您的个人资料如何处理
          <br/>
          您的个人数据将通过自动化工具在严格必要的时间内进行处理，以实现收集此类数据的目的(请参阅上表和上述数据保留部分的具体cookie持续时间)。我们采取了具体的安全、技术和
          组织措施，以防止数据丢失、非法或不正确使用和未经授权的访问。
          <br/>
          <br/>
          用户反对或编辑cookie的权力
          <br/>
          <br/>
          浏览器:
          <br/>
          您可以通过将浏览器配置为禁用cookie的方式来反对在您的硬盘上存储cookie。我们列出了主要浏览器的程序如下:
          <br/>
          •	Internet Explorer
          <br/>
          •	Chrome: https://support.google.com/chrome/answer/95647?hl=it
          <br/>
          •	Firefox: https://support.mozilla.org/it/kb/Gestione%20dei%20cookie
          <br/>
          •	Safari: http://support.apple.com/kb/HT1677?viewlocale=it_IT
          <br/>
          <br/>
          然而，在完成上述操作后，一些网页功能可能无法正常工作。
          <br/>
          <br/>
          数据控制方
          <br/>
          cookie收集的数据的控制方为Valentino S.p.A.，注册办事处位于意大利米兰(MI)的Turati Via 16/18, 20121。Valentino S.p.A.已经任命了一名数据保护官，可以通过以下电子邮件地址
          privacy@valentino.com与其联系。有关您个人数据处理的任何信息、投诉和行使下述权利，您也可写信至Valentino, via Turati 16/18, 20121 Milano以联系数据控制方(由法律部门受理，
          该部门被指定接收和管理此类请求，根据Valentino内部程序，此事也牵涉到一些相关部门)。
          <br/>
          <br/>
          谁将获知与这些数据、其转移和披露有关的信息
          <br/>
          cookies收集的数据将会并可能会涉及以下内容:
          <br/>
          • 数据控制方的雇员与合作者，以被授权进行数据处理的人的身份行事;而且,
          <br/>
          • 以数据处理者的身份提供技术和组织服务的供应商。
          <br/>
          我们可能会将通过使用cookie获得的数据传输到国外，包括欧盟以外的国家，但保证提供适当的保护和防御。如需获取有关数据确切位置的信息，请写信发送至privacy@valentino.com
          与数据控制方联系。
          <br/>
          这些数据一般不会以任何方式披露。
          <br/>
          <br/>
          用户权利
          <br/>
          用户始终有权从VALENTINO获得关于其个人数据（即使尚未记录）是否存在的确认，并以可理解的形式被告知。用户还有权获取此类信息：个人数据来源；个人数据处理的目的和方
          法，在电子仪器的帮助下进行数据处理时所应用的逻辑；数据控制方和数据处理器的识别详细信息；以及可能被传送其个人资料的人士或人士类别的标识。用户也有权提出如下要求：
          更新、更正或包含（如用户有兴趣这样做)个人数据、删除、转换为匿名形式或阻止违法处理的个人数据（包括就收集或随后处理的目的而言无需保留的数据）； 份声明，说明上述
          操作的内容已向接收数据的各方披露，但证明此类操作不可能或需要使用明显与受保护权利不成比例的方法的情况除外。用户还可以要求其数据具有可移植性。此外，用户有权要求限
          制对其个人资料的处理。
          <br/>
          <br/>
          然而，用户有权以合法理由部分或全部反对处理与他/她有关的个人数据，即使这与收集的范围有关。
          <br/>
          <br/>
          您可以行使上述权利，并通过发送电子邮件至privacy@valentino.com联系VALENTINO和数据保护官询问相关信息和有关您个人数据处理的进 步问题(请注意，发送电子邮件请求后我
          们需要获取发件人地址，这是响应请求和跟踪请求以及出于法律目的跟踪电子通信中包含的任何其他个人数据所必需的)。您也可以写信至VALENTINO, via Turati 16/18, 20121, Milano
          (Italy)，由法务部受理。
          <br/>
          <br/>
          最后请注意，您有权向意大利数据保护局(Garante per la Protezione dei Dati Personali，其地址为:Piazza Venezia, 11,00187 Rome，网址为www.garanteprivacy.it)或您所在国家的其他
          数据保护监管机构提出投诉。
          </span>
        </p>
      </div>
    </div>
  );
};

export default CookiesCn;
