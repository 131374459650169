import React from 'react';

const CookiesJp = () => {
  return (
    <div className="EN">
      <div>
     
        <p className="p1">
          <span className="s1">
          クッキーとその用途
          <br/>
          クッキーとは、固有の匿名コードの形でウェブサーバーからお使いのブラウザへ送信され、お使いのデバイス（パソコン、スマートフォン、タブレット）のハードディスク上に保
          存される小さなデータファイルのことです。クッキーは、インターネットサイトの適切な使い方を可能にしたり（いわゆる技術的クッキーもしくは必須クッキー）、または広告
          メッセージを提案するためにユーザーのオンライン閲覧における嗜好をチェック（いわゆるプロファイリングクッキー）したりする役目を果たします。
          <br/>
          クッキーはさまざまな期間にわたりデバイス上に永続的に保存されたり（いわゆる永続クッキー）、またはブラウザを閉じたときに消去されたり、限定された期間だけ存続したり
          （いわゆるセッションクッキー）します。クッキーには、閲覧中のウェブサイトによってインストールされる（いわゆるファーストパーティークッキー）ものもあれば、その他の
          ウェブサイトによってインストールされる（いわゆるサードパーティークッキー）ものもあります。
          </span>
        </p>
        <br/>

        <p className="p1">
          <span className="s1">   
              本ウェブサイトで使われるクッキーとその目的
              <br/>
              本ウェブサイトは次のクッキーを使用します:
              <br/>
              <br/>
              必須クッキー/技術的クッキー
              <br/>
              これらのクッキーにより、ユーザーの初回閲覧時から適した言語でサイトの内容を表示させて、ウェブサイトを正常に機能させることができるようになりますので、これが前述の
              必須な技術的クッキーの目的です。これらのクッキーは、ユーザーが接続している国を識別でき、毎回の閲覧時にユーザーがその国に適用されるウェブサイトのバージョンへ自動
              的に移動します。これらは、ウェブサイトを機能させるために必要なクッキーです。
              <br/>
              <br/>
              クッキー一覧
              <br/>
              クッキーとは、ユーザーがウェブサイトを閲覧したときにユーザーの使用言語やログインの詳細などの情報を記憶しておくために、ウェブサイトがブラウザに対してデバイス上に
              保存するよう求める小さなデータ（テキストファイル）のことです。これらのクッキーは、当社が設定するものであり、ファーストパーティークッキーと呼ばれます。当社では、
              広告やマーケティングのために、サードパーティークッキー（閲覧中のウェブサイト以外のドメインによるクッキー）も使用します。当社では、特に次の目的でクッキーおよびそ
              の他の追跡技術を使用します:
              <br/>
              <br/>
          </span>
        </p>
        

        <div className="my-10" >
          <table className="w-full table-auto">
            <thead>
              <tr>
                <td>クッキーサブグループ</td>
                <td>クッキー</td>
                <td>目的</td>
                <td>使用されるクッキー</td>
                <td>存続期間</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>valentino.com</td>
                <td>ブラウザの言語</td>
                <td>デフォルト言語</td>
                <td>ファーストパーティー</td>
                <td>セッション</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br/>
        <p className="p1">
          <span className="s1">
          分析クッキ
          これらのクッキーは、例えばウェブサイトの閲覧に使用されたデバイス、閲覧したページの数、特定のページでのクリック回数など、ユーザーによるウェブサイトの閲覧の仕方に
          ついての統計解析を行うために使われます。このような分析の結果は、匿名化された形で、かつ統計的な目的に限って処理されます。ウェブサイトの使い方についての集合的な統
          計分析が処理の目的となります。当社では、（コードの一部を隠した状態で）匿名のIPアドレスを追跡するクッキーのみを使用します。そのようなクッキーは、ユーザーの割り出
          しに使うことができないため、技術的クッキーに同化され、これらを使用するために同意を得る必要はありません。
          <br/>
          <br/>
          データの保存期間
          <br/>
          それぞれのクッキーの存続期間については上の表をご覧ください。クッキーによって収集されたデータは、データ管理者によって最大[下の 覧を参照してください]の期間にわ
          たって保持された後、消去または匿名化されます。
          <br/>
          Adobe Analytics: 37カ月
          <br/>
          <br/>
          クッキー使用の法的根拠
          <br/>
          技術的クッキー（匿名化された分析クッキーも含めて）の使用には同意は必要ありません。これらのクッキーは、ウェブサイトの閲覧方法と使い方を向上させるという所有者の正
          当な利益に基づいて処理されます。
          <br/>
          <br/>
          個人情報の処理方法
          <br/>
          個人情報は、自動化されたツールによって、データ収集の目的のためにどうしても必要な期間にわたって処理されます（上の表に記載された特定のクッキーの存続期間および上述
          「データの保存期間」を参照してください）。データの損失や違法または不正な使用および不正アクセスを防ぐために、特定のセキュリティ的・技術的・組織的措置が講じられて
          います。
          <br/>
          <br/>
          クッキーに異議を唱えたり編集したりするためのユーザーの権利
          <br/>
          <br/>
          ブラウザ:
          <br/>
          お客様は、お使いのブラウザの設定でクッキーを無効にすることで、ハードディスク上へのクッキーの保存を禁止することができます。主要なブラウザで利用できる次の手順を次
          に記載します:
          <br/>
          •	Internet Explorer
          <br/>
          •	Chrome: https://support.google.com/chrome/answer/95647?hl=it
          <br/>
          •	Firefox: https://support.mozilla.org/it/kb/Gestione%20dei%20cookie
          <br/>
          •	Safari: http://support.apple.com/kb/HT1677?viewlocale=it_IT
          <br/>
          <br/>
          ただし、上記の操作を行うと、ウェブページの一部の機能が正常に作動しなくなることがあります。
          <br/>
          <br/>
          データ管理者
          <br/>
          クッキーによって収集された情報のデータ管理者はValentino S.p.A.であり、その登記住所はVia Turati 16/18, 20121, Milano (MI), Italyです。Valentino S.p.A.はデータ保護責任者を任
          命しまし、メールアドレス privacy@valentino.com でデータ保護責任者に連絡を取ることができます。お客様の個人情報の取り扱いに関して問い合わせたり、苦情を訴えたり、上
          述の権利を行使したりするには、 次の住所のヴァレンティノのデータ管理者あてに連絡を取ることもできます: Via Turati 16/18, 20121 Milano (法務部宛て。ヴァレンティノの社内
          手順書に基づき、場合によっては関連する他の部署も関与させてそのような問い合わせやリクエストを受信・管理する部署として指定されています。)。
          <br/>
          <br/>
          データを知り得る者、データの転送および開示について
          <br/>
          クッキーによって取得されたデータは次の者が知ることになる可能性があります:
          <br/>
          •	データ管理者の従業員および協力者で、データの処理を行う権限が与えられた者
          <br/>
          •	技術的・組織的サービスのサプライヤーで、データ処理者として活動する者
          <br/>
          当社では、クッキーを使用して取得したデータを、適切な保護と防御を保証しつつ、EU圏外の国々を含む国外へ転送することがあります。データの転送先の正確な場所に関する情
          報を入手するには、次のアドレスのデータ管理者までお問い合わせください: privacy@valentino.com
          <br/>
          データは通常いかなる方法でも開示されることはありません。
          <br/>
          <br/>
          ユーザーの権利
          <br/>
          ユーザーには、個人情報が未登録の場合であっても、ヴァレンティノから、そのユーザーに関する個人情報が存在するか否かについての確認を取り、情報を理解可能な形式で知ら
          せてもらう権利が認められています。ユーザーには、さらに、個人情報の入手源、処理の目的と方法、電子的手段を利用して処理が行われる場合に適用されるロジック、データ管
          理者およびデータ処理者の身元の詳細情報、個人情報が通知される可能性のある者およびカテゴリーについての情報を入手する権利が認められています。 ユーザーには、本人の
          意思で個人情報の更新、修正、追加を要求したり、個人情報の消去、匿名化、個人情報に違法な処理が行われた場合にその阻止を求める権利が認められています。この対象には収
          集の本来の目的やその後の処理に関連して保存する必要がないデータも含まれます。上記の操作が、その内容に関するものも含めて、データの通知先に開示されたという声明を求
          める権利が認められています。ただし、そのような行為が不可能であることが証明された場合や、保護された権利に対して明らかに不釣り合いな方法の使用が伴う場合はこの限り
          ではありません。ユーザーは個人情報の可搬性を請求することもできます。さらに、ユーザーには、個人情報の処理の制限を求める権利も認められています。
          <br/>
          <br/>
          ユーザーには、個人情報の収集目的に叶う場合であっても、正当な理由があれば、部分的または全体的に個人情報の処理に対して異議を唱える権利が認められています。
          <br/>
          <br/>
          ユーザーは、上記の権利を行使し、情報を問い合わせることができます。次のアドレスあてにメールを送信することでヴァレンティノおよびデータ保護責任者へ詳細を問い合わせ
          ることができます: privacy@valentino.com （電子メールによるお問い合わせの送信に伴い、リクエストに返信したり、そのやりとりを追跡したりするのに必要な送信者のアドレス
          や、法的目的で電子通信に含まれるその他の個人情報が取得されることにご注意ください）。このほか、次の住所で郵便にてヴァレンティノ（法務部宛て）へ連絡を取ることもで
          きます: Via Turati 16/18, 20121, Milano (Italy)
          <br/>
          <br/>
          そして最後に、ユーザーにはイタリアのデータ保護当局（個人情報保護保証人、Piazza Venezia, 11, 00187 Rome、www.garanteprivacy.it）またユーザーのお住まいの国のその他の
          データ保護監督当局へ苦情を申し立てる権利が認められていることにご留意ください。
          </span>
        </p>
      </div>
    </div>
  );
};

export default CookiesJp;
