import React from 'react';

const CookiesIt = () => {
  return (
    <div className="EN">
      <div>
        <p className="p1">
          <span className="s1"><b>CHE COSA SONO I COOKIE E PER CHE COSA VENGONO UTILIZZATI?</b></span>
        </p>
        <p className="p1">
          <span className="s1">Un cookie è un piccolo file di dati che, sotto forma di codice anonimo unico, viene inviato al browser da un server web e poi memorizzato sul disco rigido del dispositivo in uso
          (computer, smartphone e/o tablet). I cookie possono consentire l'uso corretto di un sito internet (i cosiddetti cookie tecnici o necessari), o possono fungere da controlli delle
          preferenze dell'Utente nel contesto della navigazione online per proporre messaggi promozionali (i cosiddetti cookie di profilazione).
          <br/>
          I cookie possono essere archiviati in permanenza sul dispositivo per un periodo di tempo variabile (i cosiddetti cookie permanenti), oppure possono essere eliminati alla chiusura del
          browser o avere durata limitata (i cosiddetti cookie di sessione). I cookie possono essere installati dal sito web visitato (i cosiddetti cookie di prima parte) o possono essere installati
          da altri siti web (i cosiddetti cookie di terze parti)</span>
        </p>
        <br/>
        <p className="p1">
          <span className="s1"><b>COOKIE UTILIZZATI SU QUESTO SITO WEB E FINALITÀ DI TALE USO</b></span>
        </p>
        <p className="p1">
          <span className="s1">Questo Sito web usa i seguenti cookie:</span>
        </p>
        <br/>
        <p className="p1">
          <span className="s1"><b>Cookie necessari/tecnici</b></span>
        </p>
        <p className="p1">
          <span className="s1">Questi cookie consentono al Sito web di funzionare correttamente, in modo da visualizzarne i contenuti nella lingua e nel mercato correlati, sin dalla prima visita dell'Utente. Sono in
                grado di riconoscere il Paese dal quale si connette l'Utente e garantiscono che, a ogni visita, l'Utente sia orientato automaticamente alla versione del Sito web applicabile per il suo
                Paese. Questi cookie sono necessari per il funzionamento del Sito web.</span>
        </p>
        <br/>
        <p className="p1">
          <span className="s1"><b>Elenco di cookie</b></span>
        </p>
        <p className="p1">
          <span className="s1">Un cookie è formato da una piccola quantità di dati (un file di testo) che un sito web chiede al browser di memorizzare sul dispositivo in occasione della visita di un Utente per
            ricordarne le informazioni, ad esempio la sua lingua preferita o i dati di accesso. Questi sono cookie impostati da noi e chiamati cookie di prima parte. Utilizziamo anche cookie di
            terze parti (cookie di un dominio diverso dal sito web che si sta visitando) per finalità di pubblicità e marketing. In particolare, utilizziamo cookie e altre tecnologie di trattamento per
            queste finalità:</span>
        </p>
        <br/>
        <div className="my-10" >
          <table className="w-full table-auto">
            <thead>
              <tr>
                <td>Sottogruppo di cookie</td>
                <td>Cookie</td>
                <td>Finalità</td>
                <td>Cookie utilizzati</td>
                <td>Durata</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>valentino.com</td>
                <td>Lingua del browser</td>
                <td>Lingua predefinita</td>
                <td>Prima parte</td>
                <td>Sessione</td>
              </tr>
            </tbody>
          </table>
        </div>
        <br/>
        <p className="p1">
          <span className="s1"><b>Cookie analitici</b></span>
        </p>
        <p className="p1">
          <span className="s1">Questi cookie vengono utilizzati per creare analisi statistiche sul modo in cui gli Utenti navigano nel Sito web, ad esempio il dispositivo usato, il numero di pagine visitate o il numero
            di clic su una specifica pagina durante la navigazione. I risultati di queste analisi vengono elaborati in modo anonimo ed esclusivamente per finalità statistiche. Le finalità del sito
            Web sono le analisi aggregate statistiche sull'utilizzo del Sito web. Utilizziamo esclusivamente i cookie che tracciano gli indirizzi IP anonimi (con una parte del codice non visibile);
            questi cookie sono assimilati ai cookie tecnici perché non consentono l'identificazione dell'Utente e il loro uso non richiede consenso.
          </span>
        </p>
        <br/>
        <p className="p1">
          <span className="s1"><b>CONSERVAZIONE DEI DATI</b></span>
        </p>
        <p className="p1">
          <span className="s1">
            La durata di ogni cookie è indicata nelle tabelle di seguito. I dati raccolti dai cookie saranno conservati dal Titolare del trattamento per un massimo di [fare riferimento all'elenco
            seguente] e poi cancellati e resi anonimi.
            <br/>
            Adobe Analytics: 37 mesi
          </span>
        </p>
        <br/>
        <p className="p1">
          <span className="s1"><b>FONDAMENTO GIURIDICO PER L'UTILIZZO DEI COOKIE</b></span>
        </p>
        <p className="p1">
          <span className="s1">Per l'utilizzo dei cookie tecnici non è richiesto alcun consenso (tra questi i cookie analitici resi anonimi); vengono quindi trattati sulla base del legittimo interesse del titolare per
            migliorare la navigazione e la fruizione del Sito web.
          </span>
        </p>
        <br/>
        <p className="p1">
          <span className="s1"><b>COME VENGONO TRATTATI I DATI PERSONALI</b></span>
        </p>
        <p className="p1">
          <span className="s1">I dati personali verranno trattati con strumenti automatizzati per il tempo strettamente necessario a realizzare le finalità per cui erano stati raccolti (v. la durata degli specifici cookie
              sulle tabelle in alto e la sezione CONSERVAZIONE DEI DATI in alto). Sono state adottate specifiche misure di sicurezza, tecniche e organizzative per prevenire la perdita dei dati, usi
              non corretti o illeciti e accessi non autorizzati.
          </span>
        </p>
        <br/>
        <p className="p1">
          <span className="s1"><b>DIRITTO DEGLI UTENTI DI OPPORSI O MODIFICARE I COOKIE</b></span>
        </p>
        <p className="p1">
          <span className="s1">Browser:
            <br/>
            L'Utente ha il diritto di opporsi alla memorizzazione dei cookie sul disco rigido configurando il browser in modo da disattivare i cookie. Riportiamo di seguito le procedure disponibili
            nei browser principali:
            <br/>
          </span>
        </p>
        <p className="p1">
          <span className="s1">• 	Internet Explorer </span>
        </p>
        <p className="p1">
          <span className="s1">• Chrome: <a href="https://support.google.com/chrome/answer/95647?hl=it">https://support.google.com/chrome/answer/95647?hl=it</a></span>
        </p>
        <p className="p1">
          <span className="s1">• Firefox: <a href="https://support.mozilla.org/it/kb/Gestione%20dei%20cookie">https://support.mozilla.org/it/kb/Gestione%20dei%20cookie</a></span>
        </p>
        <p className="p1">
          <span className="s1">• Safari: <a href="http://support.apple.com/kb/HT1677?viewlocale=it_IT">http://support.apple.com/kb/HT1677?viewlocale=it_IT</a></span>
        </p>

        <p className="p1">
          <span className="s1">È possibile, comunque, che dopo aver completato l'operazione di cui sopra diverse funzioni della pagina web non funzionino correttamente.</span>
        </p>
        <br/>
        <p className="p1">
          <span className="s1"><b>TITOLARE DEL TRATTAMENTO</b></span>
        </p>
        <p className="p1">
          <span className="s1">Il titolare del trattamento dei dati raccolti è Valentino S.p.A. con sede legale in Via Turati 16/18, 20121, Milano (MI), Italia. Valentino S.p.A. ha nominato un responsabile della
            protezione dei dati, che può essere contattato al seguente indirizzo e mail: privacy@valentino.com. Per ogni informazione sul trattamento dei dati personali, per sporgere reclami o
            esercitare i diritti indicati di seguito, è possibile contattare il titolare del trattamento scrivendo a Valentino, via Turati 16/18, 20121 Milano (alla c.a. dell'ufficio legale, che è
            responsabile di ricevere ed evadere queste richieste, coinvolgendo anche gli uffici interessati, in conformità con le procedure interne di Valentino).
          </span>
        </p>
        <br/>
        <p className="p1">
          <span className="s1"><b>CHI AVRÀ CONOSCENZA DEI DATI, DEL LORO TRASFERIMENTO E DELLA LORO DIVULGAZIONE</b></span>
        </p>
        <p className="p1">
        I dati acquisiti dai cookie potranno e saranno messi a conoscenza delle seguenti persone:
        <br/>
          •	dipendenti e collaboratori del titolare del trattamento che agisce in veste di persona autorizzata a eseguire il trattamento; e,
          <br/>
          •	fornitori di servizi tecnici e organizzativi che agiscono in veste di responsabili del trattamento dei dati.
          <br/>
          Ci riserviamo di trasferire all'estero i dati acquisiti mediante l'uso dei cookie, anche verso Paesi esterni all'UE, con la garanzia di misure di difesa e tutela appropriate. Per ottenere
          informazioni sulla posizione locale dei dati, scrivere al titolare presso privacy@valentino.com.
          <br/>
          In generale i dati non saranno divulgati in alcun modo.
        </p>
        <br/>
        <p className="p1">
          <span className="s1"><b>DIRITTI DELL'UTENTE</b></span>
        </p>
  
        <p className="p1">
          <span className="s1">
          L'Utente ha sempre il diritto di richiedere a VALENTINO di confermare se esistono dati personali che Lo/La riguardano, anche se non sono ancora registrati, e di averne
          comunicazione in forma intellegibile. Un Utente ha inoltre il diritto di ricevere informazioni sulla fonte dei dati personali, le finalità e i metodi di trattamento, la logica applicata
          all'evento del trattamento eseguito con l'aiuto di strumenti elettronici, i dettagli di identificazione del responsabile e del titolare del trattamento dei dati e l'indicazione delle persone
          o categorie di persone i cui dati personali potrebbero essere comunicati. Un Utente ha altresì il diritto di richiedere un aggiornamento, una rettifica oppure se di Suo interesse
          un'inclusione di dati personali, una cancellazione, una conversione in formato anonimo o il blocco dei dati personali che sono stati trattati in violazione della legge, ivi inclusi i dati
          che non è necessario conservare per le finalità per cui erano stati raccolti o successivamente trattati. Ha inoltre il diritto di richiedere una comunicazione attestante che tutte le
          operazioni di cui sopra sono state completate, anche in riferimento ai contenuti, fatto salvo il caso in cui tale prestazione risulti impossibile o comporti il ricorso a metodi chiaramente
          esorbitanti rispetto al diritto tutelato. L'Utente può richiedere anche la portabilità dei Suoi dati. Ha, inoltre, il diritto di chiedere una limitazione del trattamento degli stessi.
          <br/>
          <br/>
          Un Utente ha il diritto di opporsi, parzialmente o totalmente, al trattamento dei dati personali che Lo/La riguardano, se sussistono legittimi motivi per farlo, e tanto a prescindere
          dalla pertinenza rispetto alla finalità della raccolta.
          <br/>
          <br/>
          Contattare VALENTINO e la persona Responsabile della protezione dei dati, scrivendo a privacy@valentino.com, per far valere tutti i diritti precedenti, per chiedere informazioni e per
          porre eventuali ulteriori domande sul trattamento dei dati personali. Sottolineiamo che quando si invia una richiesta via mail, l'indirizzo del mittente sarà acquisito per poter
          rispondere alle sue richieste e tenerne traccia e, con l'indirizzo, anche altri dati personali inclusi nella comunicazione elettronica, a fini legali. È possibile anche scrivere per posta a
          VALENTINO, via Turati 16/18, 20121, Milano (Italia), alla cortese attenzione dell'Ufficio legale.
          <br/>
          <br/>
          Comunichiamo, infine, che l'Utente ha il diritto di sporgere un reclamo al Garante per la Protezione dei Dati Personali, a Piazza Venezia, 11, 00187 Roma (www.garanteprivacy.it) o
          rivolgendosi ad altra autorità garante nel Paese di residenza.
          </span>
        </p>
      </div>
    </div>
  );
};

export default CookiesIt;
