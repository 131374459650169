import React, { useState, useEffect, useRef } from 'react';
import logoBig from '../img/logo-big.svg';
import { useTranslation } from 'react-i18next';
import CookiesEn from './cookies/cookiesEn';
import CookiesIt from './cookies/cookiesIt';
import CookiesJp from './cookies/cookiesJp';
import CookiesCn from './cookies/cookiesCn';
import CookiesKr from './cookies/cookiesKr';

const Footer = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [modalOpen, setModalOpen] = useState(false);
  const modalCookieRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      localStorage.setItem(
        'deviceDetected',
        window.innerWidth > 1100 ? 'desktop' : 'mobile'
      );
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleCookieClick = () => {
    localStorage.setItem('cookieModal', 'open');
    setModalOpen(true);
    document.getElementById('language-btn').style.display = 'none';
    const modalCookie = modalCookieRef.current;
    if (modalCookie) {
      modalCookie.classList.add('is-open');
      document.body.classList.add('modal-expanded', 'cookie-expanded');
    }
  };

  useEffect(() => {
    const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    const openCookieCta = document.querySelectorAll('.cookie-cta');

    const eventHandler = (e) => {
      e.preventDefault();
      handleCookieClick();
    };

    openCookieCta.forEach((button) => {
      button.addEventListener(isTouchDevice ? 'touchstart' : 'click', eventHandler);
    });

    return () => {
      openCookieCta.forEach((button) => {
        button.removeEventListener(isTouchDevice ? 'touchstart' : 'click', eventHandler);
      });
    };
  }, []);

  return (
    <div className="footer-container">
      <footer className="wrap-footer desktop-footer">
        <div className="footer">
          <button
            className="footer__link js-open-modal cookie-cta"
            data-modal="#cookie"
            onClick={handleCookieClick}
          >
            {t('COOKIE_POLICY_LABEL')}
          </button>
        </div>
      </footer>
      <div
        className="modal modal--cookie"
        id="cookie"
        ref={modalCookieRef}
        aria-hidden={!modalOpen}
      >
        <div className="modal-page">
          <h2>{t('COOKIE_POLICY_LABEL')}</h2>
          {currentLanguage === 'en' && <CookiesEn />}
          {currentLanguage === 'it' && <CookiesIt />}
          {currentLanguage === 'jp' && <CookiesJp />}
          {currentLanguage === 'cn' && <CookiesCn />}
          {currentLanguage === 'kr' && <CookiesKr />}
        </div>
      </div>
      <div className="preload">
        <picture className="preload__cta-img">
          <img src={logoBig} width={160} height={89} alt="" />
        </picture>
      </div>
      <div className="overflow-bg"></div>
    </div>
  );
};

export default Footer;
