import React, { useState, useEffect } from 'react';
import logo from '../img/logo.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Header = () => {
    let location = useLocation();
    const navigate = useNavigate();
    const [, setModalOpen] = useState(null);
    const modalLang = document.getElementById('langs');
    const fromWizard = localStorage.getItem('fromWizard') === 'true';
    
    const openLanguageModal = () => {
        console.log("CLICK")
        setModalOpen(true); // Changed to handle boolean state
        document.body.classList.add('langs-expanded');
        if (modalLang) {
          modalLang.classList.add('is-open'); // Ensure modalLang exists
        } 
      };
      
      const closeLanguageModal = () => {
        setModalOpen(false); // Changed to handle boolean state
        modalLang.classList.remove('is-open'); // toggles to` ref CSS styles`
      };

      const handleGoBack = () => {
        document.getElementById('language-btn').style.display = 'block';
        const modalCookie = document.querySelector('#cookie');
        const cookieModal = localStorage.getItem('cookieModal');
    
        if (cookieModal === "open") {
            document.body.classList.remove('cookie-expanded');
            document.documentElement.classList.remove('cookie-expanded');
            if (modalCookie) modalCookie.classList.remove('is-open');
            localStorage.setItem('cookieModal', 'close');
        } else {
            navigate(-1);
        }
 
    };
    
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    const changeLanguage = (language) => {
        i18n.changeLanguage(language);
        localStorage.setItem('selectedLanguage', language);
    };

    useEffect(() => {
        const changeLangEvent = document.getElementById('langsSwitcher');
        changeLangEvent.addEventListener('touchend', (event) => {
            event.preventDefault();
           
        });
    }, []);

    const shouldDisplayHeaderActions =
    location.pathname.includes('/product') || fromWizard || location.pathname.includes('/shops');

  return (

    <div>
        <header className="wrap-header">
        <div className="header">
        <div className="header__actions header-actions header-actions--left">
            <div className="header-actions__item header-actions__item--go-back">
                    <button className="header-actions__btn header-actions__btn--go-back close-btn"
                            type="button"
                            aria-label="BACK"
                            onClick={handleGoBack}>
                        <i className="icon-close" />
                    </button>
                </div>
            </div>
        <a href="/" className="header__logo logo">
            <picture className="logo__img">
            <img src={logo} width={46} height={25} alt="" />
            </picture>
        </a>
        <div className="header__actions header-actions header-actions--right hide-lang-div ">
            <div className="header-actions__item" id="language-btn">
                
                <a
                    onClick={openLanguageModal}
                    onTouchStart={openLanguageModal}
                    href="#langs"
                    className="header-actions__btn header-actions__btn--langs js-open-modal"
                    data-modal="#langs"
                    id="langsSwitcher"
                    >
                    <i className="icon-arrow"/>
                    <span>{currentLanguage}</span>
                </a>
              
            </div>
        </div>
        </div>
        </header>
        <div className="modal modal--langs" id="langs" aria-hidden="true">
                <div className="modal-top-bar">

                <div className="modal-title">{t('SELECT_LANGUAGE_MODAL_TITLE')}</div>
                </div>
                <div className="lang-actions">
                    <div className={`lang-actions__item ${currentLanguage === 'en' ? 'lang-actions__item--selected' : ''}`}>
                        <button onClick={() => changeLanguage('en')} className="lang-actions__btn btn">
                            {currentLanguage === 'en' &&  <div>English</div>}
                            {currentLanguage === 'it' &&  <div>Inglese</div>}
                            {currentLanguage === 'jp' &&  <div>日本語</div>}
                            {currentLanguage === 'cn' &&  <div>英语</div>}
                            {currentLanguage === 'kr' &&  <div>영어</div>}
                        </button>
                    </div>

                    <div className={`lang-actions__item ${currentLanguage === 'it' ? 'lang-actions__item--selected' : ''}`}>
                        <button onClick={() => changeLanguage('it')} className="lang-actions__btn btn">
                            {currentLanguage === 'en' &&  <div>Italian</div>}
                            {currentLanguage === 'it' &&  <div>Italiano</div>}
                            {currentLanguage === 'jp' &&  <div>イタリア語</div>}
                            {currentLanguage === 'cn' &&  <div>意大利语</div>}
                            {currentLanguage === 'kr' &&  <div>이탈리아어</div>}
                        </button>
                    </div>

                    <div className={`lang-actions__item ${currentLanguage === 'jp' ? 'lang-actions__item--selected' : ''}`}>
                        <button onClick={() => changeLanguage('jp')} className="lang-actions__btn btn">

                            {currentLanguage === 'en' &&  <div>Japanese</div>}
                            {currentLanguage === 'it' &&  <div>Giapponese</div>}
                            {currentLanguage === 'jp' &&  <div>日本語</div>}
                            {currentLanguage === 'cn' &&  <div>日语</div>}
                            {currentLanguage === 'kr' &&  <div>한국어</div>}
                        </button>
                    </div>
                    
                    <div className={`lang-actions__item ${currentLanguage === 'cn' ? 'lang-actions__item--selected' : ''}`}>
                        <button onClick={() => changeLanguage('cn')} className="lang-actions__btn btn">
                            {currentLanguage === 'en' &&  <div>Chinese</div>}
                            {currentLanguage === 'it' &&  <div>Cinese</div>}
                            {currentLanguage === 'jp' &&  <div>中国語（簡体字）</div>}
                            {currentLanguage === 'cn' &&  <div>简体中文</div>}
                            {currentLanguage === 'kr' &&  <div>일본어</div>}
                        </button>
                    </div> 

                    <div className={`lang-actions__item ${currentLanguage === 'kr' ? 'lang-actions__item--selected' : ''}`}>
                        <button onClick={() => changeLanguage('kr')} className="lang-actions__btn btn">
                            {currentLanguage === 'en' &&  <div>Korean</div>}
                            {currentLanguage === 'it' &&  <div>Coreano</div>}
                            {currentLanguage === 'jp' &&  <div>韓国語</div>}
                            {currentLanguage === 'cn' &&  <div>韩语</div>}
                            {currentLanguage === 'kr' &&  <div>중국어(간체)</div>}
                        </button>
                    </div>

                </div>
                <button
                    className="lang-close-model"
                    type="button"
                    aria-label="Close"
                    onClick={closeLanguageModal}>
                    <i className="icon-close" />
                </button>
        </div>
        <div className="overflow-bg"></div>
    </div>
    );
};

export default Header;