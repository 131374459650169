import React from 'react';

const CookiesKr = () => {
  return (
    <div className="EN">
      <div>
     
        <p className="p1">
          <span className="s1">
          쿠키란 무엇이며 쿠키의 용도는 무엇인가요?
          <br/>
          쿠키는 고유한 익명 코드 형태로 웹 서버에서 브라우저로 전송된 후 사용자 기기(컴퓨터, 스마트폰 및/또는 태블릿)의 하드 디스크에 저장되는 작은 데이터 파일입니다. 쿠키는 인터넷 사이트의 올바른
          사용을 가능하게 하거나(기술 쿠키 또는 필수 쿠키) 온라인 탐색이라는 맥락에서 사용자의 기본 설정을 확인하는 역할을 하여 광고 메시지를 제안(프로파일링 쿠키)할 수 있습니다.
          <br/>
          쿠키는 장치에 다양한 기간에 걸쳐 영구 저장되거나(영구 쿠키) 브라우저를 닫을 때 삭제되거나 기간을 제한(세션 쿠키)할 수 있습니다. 쿠키는 사용자가 방문하는 웹사이트에서 설치(자사 쿠키)하거
          나 다른 웹사이트에서 쿠키를 설치(타사 쿠키)할 수 있습니다.
          <br/>
          <br/>
          이 웹사이트에서 사용되는 쿠키 및 이러한 사용의 목적
          <br/>
          이 웹사이트에서 사용되는 쿠키:
          <br/>
          <br/>
          필수/기술 쿠키
          <br/>
          웹사이트가 정확하게 작동하도록 하는 쿠키로서, 소위 필수 기술 쿠키라고 불리는 이 쿠키의 목적은 사용자의 첫 방문 시 해당 언어 및 시장에 관련된 콘텐츠를 표시하는 것입니다. 사용자가 연결을 시
          도하는 국가를 인식한 후 방문할 때마다 해당 국가에 적용되는 웹사이트 버전으로 사용자를 자동으로 안내하도록 작동합니다. 이 쿠키는 웹사이트의 작동에 필수적입니다.
          <br/>
          <br/>
          쿠키 목록
          <br/>
          쿠키는 작은 크기의 데이터(텍스트 파일)이며 사용자가 웹사이트를 방문할 때 이를 저장하도록 브라우저에 요청하여 선호하는 언어 또는 로그인 세부 정보 등의 정보를 기억할 수 있게 합니다. 이 쿠키
          는 당사에서 설정하며 소위 자사 쿠키라고 불립니다. 당사는 광고 및 마케팅 목적으로 타사 쿠키(방문 중인 웹사이트가 아닌 다른 도메인의 쿠키)도 사용합니다. 특히, 당사에서 쿠키 및 기타 추적 기술
          을 사용하는 목적은 다음과 같습니다.
          </span>
        </p>
        <br/>
        
        <div className="my-10" >
          <table className="w-full table-auto">
            <thead>
              <tr>
                <td>쿠키 하위 그룹</td>
                <td>쿠키</td>
                <td>목적</td>
                <td>사용된 쿠키</td>
                <td>가용 기간</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>valentino.com</td>
                <td>브라우저 언어</td>
                <td>기본 언어</td>
                <td>자사</td>
                <td>세션</td>
              </tr>
            </tbody>
          </table>
        </div>

        <br/>
        <p className="p1">
          <span className="s1">
          분석 쿠키
          <br/>
          이 쿠키는 사용한 기기, 방문한 페이지 수 또는 탐색 중 특정 페이지의 클릭 수 등, 사용자가 웹사이트를 탐색하는 방식에 대한 통계 분석을 생성할 때 사용됩니다. 이러한 분석의 결과는 익명으로 통계
          를 내기 위해서만 처리됩니다. 이러한 처리는 웹사이트 사용에 대한 통계적 집계 분석을 위한 것입니다. 당사는 익명의 IP 주소(코드의 일부가 보이지 않음)를 추적하는 쿠키만 사용합니다. 이러한 쿠
          키는 사용자 식별을 허용하지 않으므로 기술 쿠키에 동화되고 사용 동의가 필요하지 않습니다.
          <br/>
          <br/>
          데이터 보유
          <br/>
          각 쿠키의 가용 기간은 위의 표에 표시되어 있다는 점을 알려드립니다. 쿠키에 의해 수집된 데이터는 최대 [아래 목록의 기간 참조] 동안 데이터 컨트롤러가 보유하며 이후 취소 또는 익명화됩니다.
          <br/>
          Adobe Analytics: 37개월
          <br/>
          <br/>
          쿠키 사용의 법적 근거
          <br/>
          소유자의 정당한 이익에 근거하여 처리되어 웹사이트 탐색 및 사용 개선을 제공하는 기술 쿠키(익명 분석 쿠키 포함) 사용에는 동의가 필요하지 않습니다.
          <br/>
          <br/>
          사용자의 개인 데이터 처리 방법
          <br/>
          개인 데이터는 꼭 필요한 시간 동안 해당 데이터가 수집된 목적을 달성하기 위해 자동화된 도구에 의해 처리됩니다(위의 표와 위의 데이터 보유 섹션에서 구체적인 쿠키 가용 기간 참조). 이때 구체적
          인 보안, 기술 및 조직적 조치를 채택하여 데이터 손실, 불법 또는 잘못된 사용 및 무단 액세스를 방지합니다.
          <br/>
          <br/>
          쿠키를 거부하거나 수정할 수 있는 사용자의 권리
          <br/>
          <br/>
          브라우저
          <br/>
          사용자는 다음과 같이 쿠키를 비활성화하는 방식으로 브라우저를 구성하여 사용자의 하드 디스크에 쿠키가 저장되는 것을 거부할 수 있습니다. 기본 브라우저에서 사용할 수 있는 절차:
          <br/>
          •	Internet Explorer
          <br/>
          •	Chrome: https://support.google.com/chrome/answer/95647?hl=it
          <br/>
          •	Firefox: https://support.mozilla.org/it/kb/Gestione%20dei%20cookie
          <br/>
          •	Safari: http://support.apple.com/kb/HT1677?viewlocale=it_IT
          <br/>
          <br/>
          그러나 위의 작업을 완료하고 나면, 일부 웹 페이지 기능이 제대로 작동하지 않을 수 있습니다.
          <br/>
          <br/>
          데이터 컨트롤러
          <br/>
          쿠키가 수집한 데이터의 데이터 컨트롤러는 이탈리아 밀라노(MI)의 Via Turati 16/18, 20121에 등록 사무소를 둔 Valentino S.p.A.입니다. Valentino S.p.A.가 지정한 데이터 보호 책임자는 이메일 주
          소 privacy@valentino.com으로 연락할 수 있습니다. 개인 데이터 처리와 관련된 모든 정보와 관련하여 불만을 제기하고 아래에 명시된 권리를 행사하려면, (Valentino 내부 규정에 따라 관련 부서를
          포함하여 이러한 요청을 접수하고 관리하도록 지정된 법무 부서에서 주의를 기울일 수 있도록) 이탈리아 밀라노(MI)의 Via Turati 16/18, 20121에 등록 사무소를 둔 Valentino에 연락하여 데이터 컨트
          롤러에 문의할 수 있습니다.
          <br/>
          <br/>
          데이터, 데이터 전송 및 공개에 대한 지식을 입수할 수 있는 자
          <br/>
          쿠키가 수집하는 정보를 입수할 수 있는 자:
          <br/>
          •	데이터 처리에 영향을 미칠 권한이 있는 자의 자격으로 행동하는 데이터 컨트롤러의 직원 및 협력자, 그리고
          <br/>
          •	데이터 처리자 역할을 하는 기술 공급업체 및 조직 서비스.
          <br/>
          당사는 쿠키를 사용하여 수집한 데이터를 EU 외부 국가를 포함한 해외로 전송할 수 있으며, 이 경우 적절한 보호와 방어 조치를 보장합니다. 데이터의 정확한 위치에 대한 정보를 얻으려면 이메일 주
          소 privacy@valentino.com으로 문의해 주시길 바랍니다.
          <br/>
          일반적으로 데이터는 어떤 방식으로든 공개되지 않습니다.
          <br/>
          <br/>
          사용자의 권리
          <br/>
          사용자는 항상 자신과 관련된 개인 데이터의 존재 여부에 대해 아직 기록되지 않았더라도 VALENTINO로부터 확인받고 이해하기 쉬운 형태로 전달받을 권리가 있습니다. 사용자는 또한 개인 데이터
          의 출처, 처리 목적 및 방법, 전자적 도구의 도움으로 처리되는 경우 적용되는 논리, 컨트롤러 및 데이터 처리자의 식별 세부 정보, 개인 데이터가 전달될 수 있는 자 또는 범주의 표시와 같은 정보를 얻
          을 권리가 있습니다. 또한 사용자는 업데이트, 정정, 또는 이해관계가 있을 때 법률을 위반하여 처리된 경우 수집 또는 후속 처리 목적과 관련하여 보관할 필요가 없는 데이터를 포함하여 개인 데이터
          의 포함, 삭제, 익명 양식으로의 변환 또는 개인 데이터 차단을 요청하고, 위의 작업에서 그러한 이행이 불가능하거나 보호되는 권리에 명백히 불균형적인 방법의 사용이 수반되는 경우를 예외로 하고
          콘텐츠 측면이 포함된 데이터가 전달된 당사자에게 공개되었다는 진술을 요청할 권리를 보유합니다. 사용자는 또한 데이터의 이동성을 요청할 수 있습니다. 그밖에, 사용자는 자신의 개인정보 처리 제
          한을 요청할 권리도 있습니다.
          <br/>
          <br/>
          수집 범위에 따라 적절하다 해도, 사용자는 그런데도 정당한 사유로 자신과 관련된 개인 데이터 처리의 일부 또는 전체에 대해 이의를 제기할 권리가 있습니다. 
          <br/>
          <br/>
          사용자는 VALENTINO에 문의하고 데이터 보호 책임자의 이메일 주소 privacy@valentino.com로 이메일을 보내 위의 권리를 행사하고 사용자의 개인 데이터 처리에 관한 정보 및 추가 질문을 할 수
          있습니다(법적 목적을 위해 전자 통신에 포함된 기타 개인 데이터뿐만 아니라 요청에 응답하고 추적하는 데 필요한 경우 이메일 요청을 전달하기 위해 발신자 주소의 후속 수집이 발생한다는 점을 알
          려드립니다). 또한 법무 부서에서 주의를 기울일 수 있도록 이탈리아 밀라노(MI)의 Via Turati 16/18, 20121에 등록 사무소를 둔 VALENTINO에 문의할 수 있습니다.
          <br/>
          <br/>
          마지막으로, 사용자는 로마의 Piazza Venezia, 11, 00187에 소재한(www.garanteprivacy.it) 이탈리아 데이터 보호 기관(Garante per la Protezione dei Dati Personali) 또는 사용자가 거주하는 국
          가의 다른 데이터 보호 감독 기관에 불만을 제기할 권리가 있음을 알려드립니다.
          </span>
        </p>
      </div>
    </div>
  );
};

export default CookiesKr;
